import {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    useCallback,
    useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    TablePagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Button,
    IconButton,
} from "@material-ui/core";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import { Utils } from "../../../../shared/helpers";
import { setSubscriptionData } from "../../../../state/subsription";
import {
    setSelectedRequestDocuments,
    setWebSocketUrl,
} from "../../../../state/redactDocument";
import { setPolicyConfig } from "../../../../state/policy";

import EmptyList from "../../../../shared/components/EmptyList";
import {
    AccessManagementService,
    RedactionService,
} from "../../../../services";
import moment from "moment";
import "moment-timezone";
import { useHistoryPagination } from "../../../../hooks";
import AppCheckBox from "../../../../shared/components/AppCheckBox";
import useWebSocket from "react-use-websocket";

const RedactHistory = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        refreshHistory,
    }));
    const {
        redactDocumentState,
        setSelectedRequestDocuments,
        setWebSocketUrl,
        setPolicyConfig,
    } = props;
    const selectedRequestIds = redactDocumentState.selectedRequestDocuments;
    const navigate = useNavigate();
    const [redactions, setRedactions] = useState([]);
    const [pagination, setPagination] = useHistoryPagination();
    const [selectAll, setSelectAll] = useState(false);
    const [loadingRedactions, setLoadingRedactions] = useState(false);

    // Hooks
    useEffect(() => {
        loadRedactionHistory();
        getPolicyConfig();
        return () => {
            setSelectedRequestDocuments([]);
        };
    }, []);

    useEffect(() => {
        if (redactions.length > 0) {
            changeSelectAllState();
        }
    }, [selectedRequestIds, redactions, redactDocumentState.webSocketUrl]);

    const canShowCheckBox = (redaction) => {
        return (
            (redaction.events === "completed" && redaction.reviewed) ||
            (redaction.events === "downloaded" && redaction.reviewed) ||
            (redaction.events === "error" && redaction.oldReq)
        );
    };

    // Web Socket
    const getSocketUrl = useCallback(() => {
        return new Promise((resolve) => {
            const webSocketUrl = redactDocumentState.webSocketUrl;
            if (webSocketUrl.startsWith("wss")) {
                resolve(webSocketUrl);
            } else if (webSocketUrl === "LOAD_FROM_SERVER") {
                RedactionService.getWebSocketUrl()
                    .then((response) => {
                        const socketURL = response["data"]["data"].url;
                        resolve(socketURL);
                        setWebSocketUrl(socketURL);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        });
    }, [redactDocumentState.webSocketUrl]);

    useWebSocket(getSocketUrl, {
        onOpen: () => {},
        onMessage: (message) => {
            if (message.data) {
                const fileInfo = JSON.parse(message.data);
                const matchedIndex = redactions.findIndex((r) => {
                    return r.requestId === fileInfo.requestId;
                });
                if (matchedIndex !== -1) {
                    redactions[matchedIndex] = fileInfo;
                    setRedactions([...redactions]);
                }
                if (
                    fileInfo.events === "deleted" ||
                    fileInfo.events === "uploaded"
                ) {
                    loadRedactionHistory();
                }
            }
        },
        onError: () => {},
        onReconnectStop: (attempts) => {
            if (attempts === 2) {
                setWebSocketUrl("LOAD_FROM_SERVER");
            }
        },
        shouldReconnect: (closeEvent) => {
            return true;
        },
        reconnectInterval: 1000,
        reconnectAttempts: 2,
        retryOnError: true,
        onClose: () => {},
        share: true,
    });

    // API Calls
    const reviewDocument = (redactRequest) => {
        let requestObj = { reviewedIds: [redactRequest.id] };
        RedactionService.reviewDocument(requestObj)
            .then((response) => {})
            .catch((error) => {});
    };
    const getPolicyConfig = () => {
        AccessManagementService.getPolicyConfig().then((response) => {
            const { policy } = response.data.data;
            const allowedTemplates = [];
            policy.templates.forEach((template) => {
                if (template.enabled) {
                    allowedTemplates.push({
                        title: template.displayName,
                        key: template.key,
                        enabled: false,
                    });
                }
            });
            policy.templates = allowedTemplates;
            setPolicyConfig(policy);
        });
    };

    const refreshHistory = () => {
        pagination.offset = 1;
        pagination.limit = 10;
        loadRedactionHistory();
    };

    const loadRedactionHistory = () => {
        const showLoader = redactions.length === 0;
        setLoadingRedactions(showLoader);
        const timeZone = moment.tz.guess();
        RedactionService.redactHistory(
            pagination.limit,
            pagination.offset,
            timeZone
        ).then((response) => {
            const pagination = response["data"]["data"]["pagination"];
            let redactions = response["data"]["data"]["history"];
            redactions = redactions.map((redaction) => {
                return {
                    ...redaction,
                    isSelected: false,
                };
            });
            setPagination(pagination);
            setRedactions(redactions);
            setLoadingRedactions(false);
            RedactionService.clearDocuments();
        });
    };

    const deleteHistory = (data, callback) => {
        RedactionService.deleteHistory(data)
            .then((response) => {
                Utils.handleSuccessRepsonse(response);
                callback(true);
            })
            .catch((error) => {
                Utils.handleErrorResponse({ error });
                callback(false);
            });
    };

    const getCompletedRequestIds = () => {
        const requestIds = [];
        redactions.forEach((redaction) => {
            if (canShowCheckBox(redaction)) {
                requestIds.push(redaction.id);
            }
        });
        return requestIds;
    };

    const changeSelectAllState = () => {
        const isAllSelected =
            getCompletedRequestIds().length === selectedRequestIds.length;
        setSelectAll(isAllSelected && selectedRequestIds.length > 0);
    };

    // UI Actions
    const actionHandleChangePage = (event, newPage) => {
        pagination.offset = newPage + 1;
        loadRedactionHistory();
        setSelectedRequestDocuments([]);
    };

    const actionHandleChangeRowsPerPage = (event) => {
        pagination.offset = 1;
        pagination.limit = parseInt(event.target.value, 10);
        loadRedactionHistory();
        setSelectedRequestDocuments([]);
    };

    const actionOnReviewDocuments = (redactRequest) => {
        RedactionService.selectedDocuments = [
            {
                name: redactRequest["fileName"],
                preview:
                    redactRequest["events"] === "failed" ||
                    redactRequest["events"] === "error" ||
                    redactRequest["events"] === "uploaded"
                        ? redactRequest["originalFileUrl"]
                        : redactRequest["url"],
                reRedact: redactRequest["events"] !== "uploaded",
                isPDF: true,
                templateKeywords: redactRequest["templateKeywords"],
                customKeywords: redactRequest["customKeywords"],
                reviewId: redactRequest["requestId"],
                oldReq: redactRequest["oldReq"],
                events: redactRequest["events"],
                documentId: redactRequest["id"],
            },
        ];
        if (redactRequest["events"] !== "uploaded") {
            reviewDocument(redactRequest);
        }
        navigate("./document");
    };

    const onSelectAllChange = (checked) => {
        setSelectAll(checked);
        if (checked) {
            setSelectedRequestDocuments(getCompletedRequestIds());
        } else {
            setSelectedRequestDocuments([]);
        }
    };

    const onRedactionSelect = (event, index) => {
        const checked = event.target.checked;
        const requestId = redactions[index]["id"];
        if (checked) {
            setSelectedRequestDocuments([...selectedRequestIds, requestId]);
        } else {
            let idIndex = selectedRequestIds.indexOf(requestId);
            if (idIndex > -1) {
                selectedRequestIds.splice(idIndex, 1);
                setSelectedRequestDocuments([...selectedRequestIds]);
            }
        }
    };

    const actionOnDeleteHistory = (fileId) => {
        const payload = {
            deletedIds: [fileId],
        };
        deleteHistory(payload, (status) => {
            if (status) {
                loadRedactionHistory();
            }
        });
    };

    // UI Elements
    const getRedactionStatusText = (status, reviewed) => {
        if (status === "completed" && !reviewed) {
            return "Review Required";
        } else if (status === "completed" && reviewed) {
            return "Ready for Download";
        } else if (
            status === "error" ||
            status === "failed" ||
            status === "unsuccessfull"
        ) {
            return "Unsuccessful";
        } else if (
            status === "yet to process" ||
            (status === "uploaded" && !reviewed)
        ) {
            return "Uploaded";
        } else if (status === "processing") {
            return "Processing";
        } else if (status === "converting") {
            return status;
        }
    };
    const getRedactionStatus = (status, reviewed) => {
        const statusText = getRedactionStatusText(status, reviewed);
        if (status === "completed" && !reviewed) {
            return (
                <Typography className="redaction-status btn-inprogress">
                    {statusText}
                </Typography>
            );
        } else if (status === "completed" && reviewed) {
            return (
                <Typography className="redaction-status btn-success">
                    {statusText}
                </Typography>
            );
        } else if (
            status === "error" ||
            status === "failed" ||
            status === "unsuccessfull"
        ) {
            return (
                <Typography className="redaction-status btn-danger">
                    {statusText}
                </Typography>
            );
        } else if (
            status === "yet to process" ||
            (status === "uploaded" && !reviewed)
        ) {
            return (
                <Typography className="redaction-status btn-blue">
                    {statusText}
                </Typography>
            );
        } else if (status === "processing") {
            return (
                <Typography className="redaction-status btn-blue">
                    {statusText}
                </Typography>
            );
        } else if (status === "converting") {
            return (
                <Typography className="redaction-status btn-blue">
                    {statusText}
                </Typography>
            );
        } else if (status === "downloaded") {
            return (
                <Typography className="redaction-status btn-downloaded">
                    Downloaded
                </Typography>
            );
        }
    };

    const getToolTipTitle = (redaction) => {
        return (
            <Box className="tooltip-container">
                <Typography className="subtitle">
                    {redaction.requestId}
                </Typography>
            </Box>
        );
    };

    const getStatus = (redaction) => {
        const redactionStatus = redaction.events;
        return (
            <Box className="file-status">
                <Tooltip
                    placement="top-start"
                    title={getToolTipTitle(redaction)}
                    className="cursor-pointer"
                >
                    <img
                        src={
                            redactionStatus === "error" ||
                            redactionStatus === "failed"
                                ? "/images/icons/icon-failed.png"
                                : "/images/icons/icon-info.png"
                        }
                        width="12"
                        height="12"
                        alt=""
                    />
                </Tooltip>
                <Typography className="ml-1 f12">{redaction.msg}</Typography>
            </Box>
        );
    };

    const getFileName = (redaction, row) => {
        return (
            <>
                <TableCell>
                    <Button
                        role="link"
                        className={
                            redaction.linkEnabled
                                ? "fileurl-preview file-link"
                                : "ocean-blue-two file-link"
                        }
                        onClick={() => {
                            if (redaction.linkEnabled) {
                                actionOnReviewDocuments(redaction);
                            }
                        }}
                        disableFocusRipple
                        disableRipple
                    >
                        <Box display="flex" alignItems="center">
                            <img
                                src={
                                    redaction.status === "failed"
                                        ? "/images/icons/icon-file.png"
                                        : "/images/icons/icon_file2.png"
                                }
                                alt=""
                                height="30"
                            />

                            <Box
                                className={
                                    redaction.status === "failed"
                                        ? "ml-1 warm-grey"
                                        : "ml-1"
                                }
                            >
                                {Utils.truncateLast(redaction.fileName)}
                            </Box>
                        </Box>
                    </Button>
                </TableCell>
            </>
        );
    };

    return (
        <>
            <Box className="history-wrapper" mb={4}>
                <Box className="history-container">
                    <Table className="redact-history-table">
                        <TableHead>
                            <TableRow
                                className="primary-border-bottom "
                                scope="row"
                            >
                                <TableCell scope="col">
                                    <AppCheckBox
                                        disabled={false}
                                        checked={selectAll}
                                        onChange={(event) => {
                                            onSelectAllChange(
                                                event.target.checked
                                            );
                                        }}
                                        areaLabel="Check this box to select all Reviewed Documents."
                                    />
                                </TableCell>
                                <TableCell scope="col">Filename</TableCell>
                                <TableCell scope="col">Uploaded</TableCell>
                                <TableCell scope="col">
                                    Redaction status
                                </TableCell>
                                <TableCell width="auto" scope="col"></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {redactions.map((redaction, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <AppCheckBox
                                                disabled={
                                                    !redaction.checkboxEnabled
                                                }
                                                checked={
                                                    selectedRequestIds.indexOf(
                                                        redaction.id
                                                    ) > -1
                                                }
                                                onChange={(event) => {
                                                    onRedactionSelect(
                                                        event,
                                                        index
                                                    );
                                                }}
                                                areaLabel={`Row ${
                                                    index + 1
                                                } Check this box to select this document`}
                                            />
                                        </TableCell>
                                        {getFileName(redaction, index)}
                                        <TableCell
                                            className={
                                                redaction.status === "failed"
                                                    ? "warm-grey"
                                                    : ""
                                            }
                                        >
                                            {moment(
                                                redaction.uploadedOn
                                            ).format("DD MMM YYYY hh:mm A")}
                                        </TableCell>
                                        <TableCell>
                                            {getRedactionStatus(
                                                redaction.events,
                                                redaction.reviewed
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {getStatus(redaction)}
                                        </TableCell>

                                        <TableCell>
                                            {redaction.events ===
                                                "processing" ||
                                            redaction.events ===
                                                "converting" ? (
                                                <IconButton
                                                    className="icon-remove disable-button-hover history-icon-delete-disabled"
                                                    disableFocusRipple
                                                    disableRipple
                                                    aria-label={`Delete ${redaction.fileName}`}
                                                >
                                                    <DeleteIcon className="delete-icon" />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    className="icon-remove disable-button-hover history-icon-delete"
                                                    onClick={() =>
                                                        actionOnDeleteHistory(
                                                            redaction.id
                                                        )
                                                    }
                                                    disableFocusRipple
                                                    disableRipple
                                                    aria-label={`Delete ${redaction.fileName}`}
                                                >
                                                    <DeleteIcon className="delete-icon" />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    {redactions.length > 0 && (
                        <TablePagination
                            rowsPerPageOptions={[2, 10, 25, 50]}
                            component="div"
                            count={pagination.totalCount}
                            page={pagination.offset - 1}
                            onChangePage={actionHandleChangePage}
                            rowsPerPage={pagination.limit}
                            onChangeRowsPerPage={actionHandleChangeRowsPerPage}
                        />
                    )}

                    {redactions.length === 0 && (
                        <EmptyList
                            showLoader={loadingRedactions}
                            showEmpty={redactions.length === 0}
                            title="No documents uploaded for redaction"
                            subTitle=""
                        />
                    )}
                </Box>
            </Box>
        </>
    );
});

const mapStateToProps = (state) => {
    return {
        subscriptionPlan: state.subscriptionState,
        profileState: state.profileState,
        redactDocumentState: state.redactDocumentState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSubscriptionData: (data) => dispatch(setSubscriptionData(data)),
        setSelectedRequestDocuments: (data) =>
            dispatch(setSelectedRequestDocuments(data)),
        setWebSocketUrl: (data) => dispatch(setWebSocketUrl(data)),
        setPolicyConfig: (data) => dispatch(setPolicyConfig(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(RedactHistory);
