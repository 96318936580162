import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric";
import { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { PdfEditorContext } from "../pdf-viewer";
import { useSelector } from "react-redux";
import store from "../../../../../state/rootReducer";
import {
    setDrawables,
    setEditorToolStyle,
} from "../../../../../state/redactDocument";
import { getEditorDimension } from "./editor-helper";

const CanvasEditor = (props) => {
    const { pageNumber, backgroundImage } = props;
    const { zoom, editorSizes, setEditorSizes } = useContext(PdfEditorContext);
    const { onReady } = useFabricJSEditor();
    const [deleteBtnStyle, setDeleteBtnStyle] = useState({
        display: `none`,
        top: 0,
        left: 0,
    });
    let origX, origY;
    let isDrawing;
    const [fabricCanvas, setFabricCanvas] = useState();
    const canEdit = useSelector(
        (state) => state.redactDocumentState["canDraw"]
    );

    useEffect(() => {
        if (fabricCanvas) {
            zoomCanvas(zoom);
        }
    }, [zoom]);

    useEffect(() => {
        if (fabricCanvas) {
            registerEvents();
            renderImageInCanvas(fabricCanvas);
        }
    }, [fabricCanvas]);

    const onFabricReady = (canvas) => {
        onReady(canvas);
        setFabricCanvas(canvas);
    };

    const zoomCanvas = (zoomLevel) => {
        if (fabricCanvas !== undefined && fabricCanvas !== null) {
            let width = 0;
            let height = 0;
            width =
                (fabricCanvas.width / fabricCanvas.getZoom() + 1) * zoomLevel;
            height =
                (fabricCanvas.height / fabricCanvas.getZoom() + 1) * zoomLevel;
            fabricCanvas.setWidth(width);
            fabricCanvas.setHeight(height);
            fabricCanvas.setZoom(zoomLevel);
            fabricCanvas.renderAll();
            editorSizes[pageNumber] = {
                ...editorSizes[pageNumber],
                width: width,
                height: height,
            };
            setEditorSizes({ ...editorSizes });
            setDeleteBtnStyle({ ...deleteBtnStyle, display: "none" });
        }
    };

    const renderImageInCanvas = (canvasEl) => {
        //Fabric editor canvas
        if (canvasEl) {
            canvasEl.stateful = true;
            canvasEl.clear();
            canvasEl.renderAll();

            const editorSize = editorSizes[pageNumber]
                ? editorSizes[pageNumber]
                : editorSizes[0];
            canvasEl.setHeight(editorSize.height);
            canvasEl.setWidth(editorSize.width);

            fabric.Image.fromURL(backgroundImage, (img) => {
                //Set Canvas size
                let { height, width, canvasScale } = getEditorDimension(img);
                if (canvasEl) {
                    canvasEl.setHeight(height * zoom);
                    canvasEl.setWidth(width * zoom);
                    canvasEl.setZoom(zoom);
                    canvasEl.renderAll();

                    store.dispatch(
                        setEditorToolStyle({
                            imgWidth: img.width,
                            imgHeight: img.height,
                            editorWidth: width,
                            editorHeight: height,
                        })
                    );

                    editorSizes[pageNumber] = {
                        width: width * zoom,
                        height: height * zoom,
                        imgWidth: img.width,
                        imgHeight: img.height,
                        editorWidth: width,
                        editorHeight: height,
                    };
                    setEditorSizes({ ...editorSizes });

                    //Scale image to fabric canvas editor width
                    img.scaleToHeight(img.height * canvasScale);

                    //Scale image to fabric canvas as background
                    canvasEl.setBackgroundImage(
                        img,
                        canvasEl.renderAll.bind(canvasEl)
                    );

                    //Redraw shapes
                    addUserDrawnShapes();
                }
            });
        }
    };

    const addUserDrawnShapes = () => {
        if (fabricCanvas) {
            const appState = store.getState();
            const redactDocumentState = appState["redactDocumentState"];
            const currentPage = redactDocumentState.pageNumber;
            const fileIndex = redactDocumentState.fileIndex;
            const shapes = getPageDrawables(fileIndex, pageNumber);
            shapes.forEach((shape) => {
                fabricCanvas.add(shape);
            });
            fabricCanvas.renderAll();
        }
    };

    const removeDeleteBtn = () => {
        setDeleteBtnStyle({
            display: "none",
            top: 0,
            left: 0,
        });
    };

    const deleteActiveObject = () => {
        if (fabricCanvas.getActiveObject()) {
            fabricCanvas.remove(fabricCanvas.getActiveObject());
            removeDeleteBtn();
            updateDrawableState();
        }
    };

    const addDeleteBtn = (x, y) => {
        const btnLeft = x + 5;
        const btnTop = y - 20 < 0 ? 0 : y - 20;
        setDeleteBtnStyle({
            display: "block",
            top: btnTop,
            left: btnLeft,
        });
    };

    const registerEvents = () => {
        if (fabricCanvas) {
            fabricCanvas.selection = false;
            fabricCanvas.defaultCursor = "crosshair";
            fabricCanvas.on("mouse:down", (o) => {
                if (fabricCanvas.getActiveObject()) {
                    fabricCanvas.setActiveObject(
                        fabricCanvas.getActiveObject()
                    );
                } else {
                    isDrawing = true;
                    var pointer = fabricCanvas.getPointer(o.e);
                    origX = pointer.x;
                    origY = pointer.y;
                    var rect = new fabric.Rect({
                        left: origX,
                        top: origY,
                        originX: "left",
                        originY: "top",
                        width: 0,
                        height: 0,
                        angle: 0,
                        hasControls: true,
                        hasRotatingPoint: false,
                        stroke: "red",
                        strokeWidth: 2,
                        noScaleCache: false,
                        strokeUniform: true,
                        fill: "transparent",
                        editable: false,
                    });
                    //Hide rotating control
                    rect.setControlsVisibility({
                        mtr: false,
                    });
                    fabricCanvas.add(rect).setActiveObject(rect);
                    updateDrawableState();
                }
            });
            fabricCanvas.on("mouse:move", (o) => {
                onMouseMove(o);
            });
            fabricCanvas.on("mouse:up", (o) => {
                isDrawing = false;
                if (fabricCanvas.getActiveObject()) {
                    const object = fabricCanvas.getActiveObject();
                    if (object.width > 3 && object.height > 3) {
                        if (o.target) {
                            addDeleteBtn(
                                o.target.oCoords.tr.x,
                                o.target.oCoords.tr.y
                            );
                        }
                    } else {
                        deleteActiveObject();
                    }
                }
            });
            fabricCanvas.on("object:selected", (e) => {
                addDeleteBtn(e.target.oCoords.tr.x, e.target.oCoords.tr.y);
            });
            fabricCanvas.on("object:scaling", function (e) {
                removeDeleteBtn();
            });
            fabricCanvas.on("object:moving", function (e) {
                removeDeleteBtn();
            });
            fabricCanvas.on("object:modified", (options) => {
                const vptCordinates = fabricCanvas.vptCoords;
                const editorWidth = vptCordinates.br.x;
                const editorHeight = vptCordinates.br.y;
                const object = options.target;
                if (
                    object.left < 0 ||
                    object.top < 0 ||
                    object.left + object.width * object.scaleX > editorWidth ||
                    object.top + object.height * object.scaleY > editorHeight
                ) {
                    object.top = object._stateProperties.top;
                    object.left = object._stateProperties.left;
                    object.angle = object._stateProperties.angle;
                    object.scaleX = object._stateProperties.scaleX;
                    object.scaleY = object._stateProperties.scaleY;
                    object.setCoords();
                    object.saveState();
                }
            });
        }
    };

    const onMouseMove = (o) => {
        if (!isDrawing) {
            return;
        }
        var pointer = fabricCanvas.getPointer(o.e);
        var activeObj = fabricCanvas.getActiveObject();
        const threshold = 2;

        // Restrict top|left outside draw
        if (origX > pointer.x) {
            if (pointer.x < 0) {
                activeObj.set({ left: threshold });
            } else {
                activeObj.set({ left: Math.abs(pointer.x) });
            }
        }
        if (origY > pointer.y) {
            if (pointer.y < 0) {
                activeObj.set({ top: threshold });
            } else {
                activeObj.set({ top: Math.abs(pointer.y) });
            }
        }

        // Restrict right|bottom outside draw
        const editorWidth = fabricCanvas.width;
        const editorHeight = fabricCanvas.height;
        const zoom = fabricCanvas.getZoom();
        if (editorWidth < pointer.x * zoom + threshold) {
            isDrawing = false;
            return;
        }
        if (editorHeight < pointer.y * zoom + threshold) {
            isDrawing = false;
            return;
        }

        activeObj.set({ width: Math.abs(origX - pointer.x) });
        activeObj.set({ height: Math.abs(origY - pointer.y) });
        activeObj.setCoords();
        fabricCanvas.renderAll();
    };

    const getFiles = () => {
        const appState = store.getState();
        const redactDocumentState = appState["redactDocumentState"];
        const files = redactDocumentState["files"];
        return files;
    };

    const getFile = (fileIndex) => {
        const file = getFiles()[fileIndex];
        return file;
    };

    const getDrawablePageIndex = (fileIndex, pageNo) => {
        const file = getFile(fileIndex);
        if (file) {
            const pageIndex = file["drawables"].findIndex((drawable) => {
                return drawable.pageNumber === pageNo;
            });
            return pageIndex;
        }
        return -1;
    };

    const getPageDrawables = (fileIndex, pageNo) => {
        const pageIndex = getDrawablePageIndex(fileIndex, pageNo);
        if (pageIndex > -1) {
            const shapes = getFile(fileIndex)["drawables"][pageIndex]["shapes"];
            return shapes;
        }
        return [];
    };

    const updateDrawableState = () => {
        const appState = store.getState();
        const redactDocumentState = appState["redactDocumentState"];
        const fileIndex = redactDocumentState.fileIndex;
        const pageIndex = getDrawablePageIndex(fileIndex, pageNumber);
        const files = getFiles();
        if (pageIndex > -1) {
            files[fileIndex]["drawables"][pageIndex]["shapes"] = [
                ...fabricCanvas._objects,
            ];
            store.dispatch(setDrawables(files));
        } else {
            const drawable = {
                pageNumber: pageNumber,
                isPDF: files[fileIndex].isPDF,
                shapes: [...fabricCanvas._objects],
            };
            files[fileIndex]["drawables"].push(drawable);
            store.dispatch(setDrawables(files));
        }
    };

    return (
        <Box
            style={{
                position: "relative",
            }}
        >
            <img
                src="/images/icons/icon_delete.jpeg"
                id="delete-btn"
                onClick={deleteActiveObject}
                style={{
                    display: `${deleteBtnStyle.display}`,
                    top: `${deleteBtnStyle.top}px`,
                    left: `${deleteBtnStyle.left}px`,
                }}
                alt=""
            />
            <FabricJSCanvas className="canvas-editor" onReady={onFabricReady} />

            {!canEdit && <Box className="edit-overlay"></Box>}
        </Box>
    );
};

export default CanvasEditor;
