import { useEffect, useRef, useState, useContext, useMemo } from "react";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CanvasEditor from "./canvas-editor";
import VisibilitySensor from "react-visibility-sensor";
import { PdfEditorContext } from "../pdf-viewer";

const PDFPage = (props) => {
    let { page, pageNumber } = props;
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [canLoadComponent, setCanLoadComponent] = useState(false);
    const [convertingImage, setConvertingImage] = useState([]);
    const canvasRef = useRef(null);
    const [imgSrc, setImgSrc] = useState("");
    const scale = 3;
    const {
        editorSizes,
        activePageNumber,
        isMovingPage,
        setIsMovingPage,
        setActivePageNumber,
    } = useContext(PdfEditorContext);

    useEffect(() => {
        if (page && canLoadComponent) {
            convertImage();
        }
    }, [page, canLoadComponent]);

    const convertImage = async () => {
        setConvertingImage(true);
        if (page) {
            const _page = await page;
            let viewport = _page.getViewport({ scale });
            setWidth(viewport.width);
            setHeight(viewport.height);
            if (canvasRef && canvasRef.current) {
                const context = canvasRef.current.getContext("2d");
                if (context) {
                    await _page.render({
                        canvasContext: context,
                        viewport,
                    }).promise;
                    if (canvasRef.current) {
                        const imgUrl = canvasRef.current.toDataURL("image/png");
                        setImgSrc(imgUrl);
                    }
                }
                setConvertingImage(false);
            }
        }
    };

    const getWidth = useMemo(() => {
        const editorSize = editorSizes[pageNumber]
            ? editorSizes[pageNumber]
            : editorSizes[0];
        const width =
            editorSize.width > 0 ? `${parseInt(editorSize.width)}px` : "50%";
        return width;
    }, [editorSizes, pageNumber]);

    const getHeight = useMemo(() => {
        const editorSize = editorSizes[pageNumber]
            ? editorSizes[pageNumber]
            : editorSizes[0];
        const height =
            editorSize.height > 0 ? `${parseInt(editorSize.height)}px` : "80vh";
        return height;
    }, [editorSizes, pageNumber]);

    return (
        <>
            <VisibilitySensor
                intervalCheck={isMovingPage ? 5000 : 500}
                partialVisibility={true}
                offset={{ top: 250, bottom: 250 }}
                scrollCheck={true}
                onChange={(visibility) => {
                    if (visibility && !isMovingPage) {
                        setActivePageNumber(pageNumber);
                        setCanLoadComponent(true);
                    }
                    if (pageNumber === activePageNumber) {
                        setTimeout(() => {
                            setIsMovingPage(false);
                            setCanLoadComponent(true);
                        }, 2000);
                    }
                }}
            >
                <>
                    {(!canLoadComponent || convertingImage) && (
                        <>
                            <Box
                                className="pdf-loader"
                                id={`pdf-page${pageNumber}`}
                                style={{
                                    minWidth: `${getWidth}`,
                                    minHeight: `${getHeight}`,
                                }}
                            >
                                <CircularProgress></CircularProgress>
                            </Box>
                            <Box className="page-number">
                                Page {pageNumber + 1}
                            </Box>
                        </>
                    )}
                    {canLoadComponent && imgSrc && (
                        <>
                            <Box
                                className="canvas-conatiner"
                                id={`pdf-page${pageNumber}`}
                                style={{
                                    minWidth: `${getWidth}`,
                                    minHeight: `${getHeight}`,
                                }}
                            >
                                <CanvasEditor
                                    backgroundImage={imgSrc}
                                    pageNumber={pageNumber}
                                />
                            </Box>
                            <Box className="page-number">
                                Page {pageNumber + 1}
                            </Box>
                        </>
                    )}
                </>
            </VisibilitySensor>
            {canLoadComponent && (
                <canvas
                    style={{ display: "none" }}
                    ref={canvasRef}
                    width={width}
                    height={height}
                />
            )}
        </>
    );
};

export default PDFPage;
