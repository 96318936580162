import {
    Box,
    Dialog,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    TextField,
    IconButton,
} from "@material-ui/core";
import { AccessManagementService, DefaultPagination } from "../../../services";
import { useState } from "react";
import AccessManageEditModal from "./AccessManageEditModal";
import EmptyList from "../../../shared/components/EmptyList";
import AppCheckBox from "../../../shared/components/AppCheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import { useDebouncedEffect } from "../../../shared/hooks";

const AccessManagement = (props) => {
    const [showSaveButton, setShowSaveButton] = useState(true);
    const [showEditModel, setShowEditModel] = useState(false);
    const [policyMembersList, setPolicyMembersList] = useState([]);
    const [loadingPolicyMembersList, setloadingPolicyMembersList] =
        useState(false);
    const [paginations, setPagination] = useState(DefaultPagination(10));
    const [memberDetail, setMemberDetail] = useState([]);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    // API Call
    const loadPolicyMembersHistory = () => {
        setloadingPolicyMembersList(true);
        AccessManagementService.GroupPolicyHistory(
            paginations.limit,
            paginations.offset
        )
            .then((response) => {
                const { groupList, pagination } = response.data.data;
                const sortMembersByOrder =
                    groupList && groupList.sort((a, b) => a.order - b.order);
                setPolicyMembersList(sortMembersByOrder);
                setPagination(pagination);
                setloadingPolicyMembersList(false);
                setIsUsersLoaded(true);
            })
            .catch(() => {
                setloadingPolicyMembersList(false);
                setIsUsersLoaded(true);
            });
    };
    //  UI Actions
    const actionHandleChangePage = (_event, newPage) => {
        paginations.offset = newPage + 1;
        loadPolicyMembersHistory();
    };

    const actionHandleChangeRowsPerPage = (event) => {
        paginations.offset = 1;
        paginations.limit = parseInt(event.target.value, 10);
        loadPolicyMembersHistory();
    };
    const onMemberEdit = (member) => {
        setShowSaveButton(true);
        setShowEditModel(true);
        setMemberDetail({
            member,
        });
    };

    const onMemberSearch = (e) => {
        setloadingPolicyMembersList(true);
        const searchTerm = e.target.value;
        setSearchValue(searchTerm);
    };
    const actionOnClearSearch = () => {
        if (searchValue !== "") {
            setSearchValue("");
        }
    };

    // Hooks

    useDebouncedEffect(
        () => {
            loadPolicyMembersHistory(1, 10, searchValue);
        },
        [searchValue],
        1000
    );

    return (
        <>
            <Box className="accessManagement-container px-6">
                {false && (
                    <Box className="search-box-container">
                        <TextField
                            className="search-box  mt-2"
                            variant="outlined"
                            size="small"
                            placeholder="Search by name or email"
                            value={searchValue}
                            onChange={(e) => onMemberSearch(e)}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        color="secondary"
                                        className="mr-2"
                                    />
                                ),
                                endAdornment: (
                                    <IconButton
                                        type="button"
                                        onClick={actionOnClearSearch}
                                    >
                                        <ClearIcon
                                            className={
                                                searchValue
                                                    ? "secondary"
                                                    : "white"
                                            }
                                        />
                                    </IconButton>
                                ),
                            }}
                        />
                    </Box>
                )}
                <Table stickyHeader className="app-table">
                    <TableHead>
                        <TableRow className="primary-border-bottom ">
                            <TableCell>Group ID</TableCell>
                            <TableCell>Group Name</TableCell>
                            <TableCell>
                                Auto <br /> Redaction
                            </TableCell>

                            <TableCell>
                                Custom <br /> Words
                            </TableCell>
                            <TableCell>
                                Draw <br />
                                Custom
                            </TableCell>
                            {false && (
                                <TableCell>
                                    Redaction <br />
                                    Percentage
                                </TableCell>
                            )}

                            <TableCell>
                                Redaction <br />
                                Templates
                            </TableCell>
                            <TableCell>Edit/View</TableCell>
                        </TableRow>
                    </TableHead>
                    {!loadingPolicyMembersList && (
                        <TableBody>
                            {policyMembersList.length !== 0 &&
                                policyMembersList.map((member) => (
                                    <TableRow key={member.userId}>
                                        <TableCell>
                                            <Typography className="user-email">
                                                {member.groupId}
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography className="user-email">
                                                {member.name
                                                    ? member.name
                                                    : "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <AppCheckBox
                                                disableRipple
                                                disableFocusRipple
                                                checked={
                                                    member.policyDetails
                                                        .autoRedactionEnabled
                                                }
                                                className="auto-renew-checkbox"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <AppCheckBox
                                                disableRipple
                                                disableFocusRipple
                                                checked={
                                                    member.policyDetails
                                                        .customRedactionEnabled
                                                }
                                                className="auto-renew-checkbox"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <AppCheckBox
                                                disableRipple
                                                disableFocusRipple
                                                checked={
                                                    member.policyDetails
                                                        .drawRedactionEnabled
                                                }
                                                className="auto-renew-checkbox"
                                            />
                                        </TableCell>
                                        {false && (
                                            <TableCell>
                                                <AppCheckBox
                                                    disableRipple
                                                    disableFocusRipple
                                                    checked={
                                                        member.policyDetails
                                                            .percentageRedactionEnabled
                                                    }
                                                    className="auto-renew-checkbox"
                                                />
                                            </TableCell>
                                        )}

                                        <TableCell>
                                            <AppCheckBox
                                                disableRipple
                                                disableFocusRipple
                                                checked={
                                                    member.policyDetails
                                                        .templateRedactionEnabled
                                                }
                                                className="auto-renew-checkbox"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <img
                                                src="/images/icons/icon_edit.svg"
                                                alt="Edit"
                                                width={22}
                                                className="edit-icon-style"
                                                onClick={() => {
                                                    onMemberEdit(member);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    )}
                </Table>
                <EmptyList
                    showLoader={loadingPolicyMembersList}
                    showEmpty={policyMembersList.length === 0 && isUsersLoaded}
                    title="No Users Found"
                    subTitle=""
                />
                {policyMembersList.length > 0 && !loadingPolicyMembersList && (
                    <TablePagination
                        rowsPerPageOptions={[2, 10, 25, 50]}
                        component="div"
                        count={paginations.total}
                        page={paginations.offset - 1}
                        onPageChange={actionHandleChangePage}
                        rowsPerPage={paginations.limit}
                        onRowsPerPageChange={actionHandleChangeRowsPerPage}
                    />
                )}

                {showEditModel && memberDetail && (
                    <>
                        <Dialog maxWidth="sm" fullWidth open={showEditModel}>
                            <DialogContent className="no-padding">
                                <AccessManageEditModal
                                    setShowEditModel={setShowEditModel}
                                    memberDetails={memberDetail.member}
                                    loadPolicyMembersHistory={
                                        loadPolicyMembersHistory
                                    }
                                    showSaveButton={showSaveButton}
                                />
                            </DialogContent>
                        </Dialog>
                    </>
                )}
            </Box>
        </>
    );
};

export default AccessManagement;
