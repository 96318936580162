import { useNavigate, useLocation } from "react-router-dom";
import React, {
    useEffect,
    useState,
    useRef,
    useContext,
    createContext,
} from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import {
    Box,
    Grid,
    Button,
    Checkbox,
    ButtonGroup,
    InputLabel,
    Typography,
    TextField,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import PDFViewer from "./pdf-viewer";
import { AppButton } from "../../../../shared/components/AppComponents";
import SystemFileDownload from "../../../../shared/components/SystemFileDownload";
import { RedactionService } from "../../../../services";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { Utils } from "../../../../shared/helpers";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import { setCanDraw, setDrawables } from "../../../../state/redactDocument";
import moment from "moment";
import "moment-timezone";
import { Scrollbars } from "react-custom-scrollbars";
import JSZipUtils from "jszip-utils";
import { useDebouncedEffect } from "../../../../shared/hooks";

export const DocumentPreviewContext = createContext({});

const DocumentPreview = (props) => {
    const { setRedactDrawables, redactDrawables, setCanDraw, policyState } =
        props;
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [processingDocument, setProcessingDocument] = useState(false);
    const [fileIndex, setFileIndex] = useState(0);
    const [keyword, setKeyword] = useState([]);
    const [redactedTemplates, setRedactedTemplates] = useState([]);
    const [redactedCustomKeywords, setRedactedCustomKeywords] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState(
        RedactionService.selectedDocuments
    );
    const fileDownloader = useRef();
    const pdfViewer = useRef();
    const [pdfPages, setPDFPages] = useState([]);
    const [focusPageNo, setFocusPageNo] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [zoom, setZoom] = useState(1);

    useDebouncedEffect(
        () => {
            const page = parseInt(focusPageNo);
            if (
                !isNaN(page) &&
                page > 0 &&
                page <= pdfPages.length &&
                pdfViewer.current &&
                focusPageNo !== 0
            ) {
                pdfViewer.current.focusPageNumber(page);
            }
        },
        [focusPageNo],
        1000
    );

    const [defaultTemplates, setDefaultTemplates] = useState(
        policyState.templates
    );
    const [preselectedTemplates, setPreselectedTemplates] = useState(
        policyState.preselectedTemplates
    );
    const [drawBox, setDrawBox] = useState(false);

    const getAbbreviatedTemplates = (keyword) => {
        switch (keyword) {
            case "ABN":
                return "ABN - Australian Business Number";
            case "TFN":
                return "TFN - Tax File Number";
            case "ACN":
                return "ACN - Australian Company Number";
            case "CCN":
                return "CCN - Credit Card Number";
            case "DCN":
                return "DCN - Debit Card Number";
            case "AN":
                return "AN - Account Number";
            case "REF":
                return "REF - Reference Number";
            case "PN":
                return "PN - Passport Number";
            case "PRN":
                return "PRN - Payment Reference Numbers";
            case "CRN":
                return "CRN - Customer Reference Number";
            case "EFT":
                return "EFT - Electronic Fund Transfer code";
            case "DLN":
                return "DLN - Driving License Number";
            default:
                break;
        }
    };

    // Hooks
    useEffect(() => {
        if (RedactionService.selectedDocuments.length === 0) {
            navigate("../");
        }
    }, [location.pathname]);

    useEffect(() => {
        const drawables = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            drawables.push({
                fileIndex: i,
                isPDF: selectedFiles[i].isPDF,
                drawables: [],
            });
        }
        setRedactDrawables(drawables);
        setCanDraw(false);
    }, [selectedFiles]);

    // TODO
    useEffect(() => {
        if (selectedFiles.length > 0) {
            if (
                (selectedFiles[0]["templateKeywords"] !== undefined &&
                    selectedFiles[0]["templateKeywords"].length > 0) ||
                (selectedFiles[0]["customKeywords"] !== undefined &&
                    selectedFiles[0]["customKeywords"].length > 0)
            ) {
                setDefaultTemplates(
                    defaultTemplates.concat(preselectedTemplates)
                );

                let redactTemplates = defaultTemplates.filter((template) => {
                    return (
                        selectedFiles[0]["templateKeywords"].indexOf(
                            template.key
                        ) === -1
                    );
                });
                const newSelectedTemplates = [];
                redactTemplates.forEach((e) => {
                    const isPredefinedIndex = preselectedTemplates.findIndex(
                        (p) => p.enabled && e.key === p.key
                    );
                    if (isPredefinedIndex > -1) {
                        newSelectedTemplates.push(e);
                    }
                });

                setDefaultTemplates(redactTemplates);
                setSelectedTemplates(newSelectedTemplates);
                setRedactedTemplates(selectedFiles[0]["templateKeywords"]);
                setRedactedCustomKeywords(selectedFiles[0]["customKeywords"]);
            } else {
                const newSelectedTemplates = [];
                defaultTemplates.forEach((e) => {
                    const isPredefinedIndex = preselectedTemplates.findIndex(
                        (p) => p.enabled && e.key === p.key
                    );
                    if (isPredefinedIndex > -1) {
                        newSelectedTemplates.push(e);
                    }
                });
                setSelectedTemplates(newSelectedTemplates);

                setRedactedTemplates([]);
                setRedactedCustomKeywords([]);
            }
        }
    }, []);

    //Instance methods
    const onPDFLoad = (pages) => {
        setPDFPages(pages);
    };

    const getDrawFields = () => {
        const pages = [];
        const editorSizes = pdfViewer.current.editorSizes;
        for (let i = 0; i < redactDrawables.length; i++) {
            const redactFile = redactDrawables[i];
            const drawables = redactFile["drawables"];
            for (let j = 0; j < drawables.length; j++) {
                const drawable = drawables[j];
                const shapes = drawable["shapes"];
                const coordinates = [];
                const pageNumber = drawable["pageNumber"];
                const editorSize = editorSizes[pageNumber];
                for (let k = 0; k < shapes.length; k++) {
                    const shape = shapes[k];
                    let scale = editorSize.imgWidth / editorSize.editorWidth;
                    scale = scale / 3;
                    const left = shape.left * scale;
                    const top = shape.top * scale;
                    const coordinate = [
                        Math.abs(left),
                        Math.abs(top),
                        Math.abs(left) + shape.width * shape.scaleX * scale,
                        Math.abs(top) + shape.height * shape.scaleY * scale,
                    ];
                    if (
                        coordinate[2] - coordinate[0] > 0 &&
                        coordinate[3] - coordinate[1] > 0
                    ) {
                        coordinates.push(coordinate.toString());
                    }
                }
                const page = {
                    pageNumber: redactFile.isPDF ? pageNumber + 1 : 1,
                    coordinates,
                };
                pages.push(page);
            }
        }
        return pages;
    };

    const havingDrawables = () => {
        const pages = getDrawFields();
        let havingShapes = false;
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            if (page.coordinates.length > 0) {
                havingShapes = true;
                break;
            }
        }
        return havingShapes;
    };

    const bindTemplateKeywords = (formData) => {
        let selectedKeywords = [];
        if (policyState.templateRedactionEnabled) {
            selectedTemplates.forEach((selectedValue) => {
                selectedKeywords.push(selectedValue.key);
            });
        }
        formData.append("fields", selectedKeywords.toString());
    };

    const prepareDataToRedaction = async () => {
        const formData = new FormData();
        if (false) {
            if (isReRedact()) {
                const file = await downloadRedactedFile(
                    selectedFiles[0].preview,
                    selectedFiles[0].name
                );
                formData.append("file", file);
            } else {
                selectedFiles.forEach((file) => {
                    if (file.file) {
                        formData.append("file", file.file);
                    }
                });
            }
        }
        formData.append("customfields", JSON.stringify(keyword));
        formData.append("color", "black");
        formData.append("docId", moment().unix());
        bindTemplateKeywords(formData);
        formData.append("draw", JSON.stringify(getDrawFields()));
        formData.append("reRedacted", isReRedact());
        formData.append("requestId", selectedFiles[0].reviewId);
        if (selectedFiles[0].reviewId !== null) {
            formData.append("oldReq", selectedFiles[0].reviewId);
        }
        return formData;
    };

    const isReRedact = () => {
        return selectedFiles.length > 0 && selectedFiles[0]["reRedact"];
    };

    const canShowDownload = () => {
        return (
            selectedFiles.length > 0 &&
            (selectedFiles[0]["events"] === "completed" ||
                selectedFiles[0]["events"] === "downloaded" ||
                selectedFiles[0]["oldReq"])
        );
    };

    const downloadRedactedFile = (url, name) => {
        return new Promise(async (resolve, reject) => {
            const fileContent = await JSZipUtils.getBinaryContent(url);
            const file = new File([new Blob([fileContent])], name, {
                type: "application/pdf",
            });
            resolve(file);
        });
    };

    //API calls
    const uploadFilesToRedact = async (callback) => {
        const formData = await prepareDataToRedaction();
        const timeZone = moment.tz.guess();
        RedactionService.redactDocument(formData, timeZone)
            .then((response) => {
                Utils.handleSuccessRepsonse(response);
                callback(true);
            })
            .catch((error) => {
                Utils.handleRedactErrorResponse({ error });
                callback(false);
            });
    };

    const downloadSelectedDocuments = (callback) => {
        setIsLoading(true);
        const timeZone = moment.tz.guess();
        const docId =
            selectedFiles.length > 0 ? selectedFiles[0].documentId : "";
        RedactionService.bulkDownloadSelected(docId, timeZone)
            .then((response) => {
                let bulkDownloadResponse = response["data"]["data"];
                callback(true, bulkDownloadResponse);
                setIsLoading(false);
            })
            .catch((error) => {
                Utils.handleRedactErrorResponse({ error });
                callback(false);
                setIsLoading(false);
            });
    };

    //UI Actions
    const actionRedactDocument = () => {
        if (
            havingDrawables() ||
            selectedTemplates.length > 0 ||
            keyword.length > 0
        ) {
            setProcessingDocument(true);
            uploadFilesToRedact((status) => {
                setProcessingDocument(false);
                if (status) {
                    actionClose();
                }
            });
        } else {
            toast.info(
                "Please select a template, enter a keyword or apply Custom Redaction."
            );
        }
    };

    const actionClose = () => {
        navigate("/redact");
    };

    const downloadFiles = (files) => {
        if (fileDownloader && fileDownloader.current) {
            const downloader = fileDownloader.current;
            const file = files[0];
            downloader.startDownFile({
                url: file.url,
                fileName: Utils.fileNameWithoutExtension(file.fileName),
                extension: ".pdf",
                documentType: "single",
            });
        }
    };

    const actionDownload = () => {
        downloadSelectedDocuments((status, response) => {
            if (status) {
                downloadFiles(response);
            }
        });
    };

    const actionOnDeleteKeyword = (templateKey) => {
        keyword.splice(templateKey, 1);
        setKeyword([...keyword]);
    };

    const resetKeyword = (event) => {
        event.target.value = "";
    };

    const addCustomKeyword = (newWord, event) => {
        const word = keyword.find((word) => {
            return word.toLowerCase() === newWord.toLowerCase();
        });
        if (word) {
            if (event != "Tab") {
                toast.error("Keywords already exist.");
            }
            return false;
        } else {
            setKeyword([...keyword, newWord]);
            return true;
        }
    };

    const actionOnChangeKeyword = (event) => {
        let templateKeyword = event.target.value;
        if (
            event.code === "NumpadEnter" ||
            event.code === "Tab" ||
            event.code === "Enter" ||
            event.type === "blur"
        ) {
            templateKeyword = templateKeyword.trim();
            if (templateKeyword.length > 0) {
                if (addCustomKeyword(templateKeyword, event.code)) {
                    resetKeyword(event);
                }
            }
        }
        if (event.code === "Tab" && templateKeyword.trim().length > 0 != "") {
            setTimeout(() => {
                event.target.focus();
            }, 100);
        }
    };

    //UI Elements
    const Tag = ({ label, onDelete, ...props }) => {
        return (
            <div className="custom-keyword-tag" {...props}>
                <CloseIcon onClick={onDelete} />
                <Button
                    className="f12 myriadpro-regular disable-button-hover "
                    disableFocusRipple
                    disableRipple
                    aria-label={label}
                >
                    {label}
                </Button>
            </div>
        );
    };

    const {
        getRootProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        value,
        focused,
        setAnchorEl,
    } = useAutocomplete({
        noOptionsText: "No Options",
        id: "customized-hook-demo",
        defaultValue: selectedTemplates,
        multiple: true,
        filterSelectedOptions: true,
        options: defaultTemplates,
        value: selectedTemplates,
        onChange: (event, newValue) => setSelectedTemplates([...newValue]),
        limitTags: 2,
        getOptionLabel: (option) => option.title,
        getOptionSelected: (option, value) => value.title === option.title,
        className: "",
    });

    const getToolTipTitle = () => {
        return (
            <Box className="tooltip-container">
                <Typography className="title">Custom Redaction</Typography>
                {selectedFiles.length > 1 ? (
                    <Typography className="subtitle">
                        Any keyword that need to be redacted.
                    </Typography>
                ) : (
                    <Typography className="subtitle">
                        By selecting this option, you can{" "}
                        <span>draw a custom box</span> over the content you want
                        to redact.
                    </Typography>
                )}
            </Box>
        );
    };

    const getDefaultTemplateTooltip = (keyword) => {
        return (
            <>
                {getAbbreviatedTemplates(keyword) ? (
                    <Tooltip title={getAbbreviatedTemplates(keyword)}>
                        <Box className="rectangle-card cursor-pointer">
                            {keyword}
                        </Box>
                    </Tooltip>
                ) : (
                    <Box className="rectangle-card">{keyword}</Box>
                )}
            </>
        );
    };

    return (
        <>
            <DocumentPreviewContext.Provider
                value={{
                    zoom,
                    setZoom,
                }}
            >
                <Box className="redact-document h-100">
                    <Box className="preview-header">
                        <Box>
                            <Typography
                                className="f18 myriadpro-regular"
                                variant="h1"
                            >
                                {selectedFiles.length > 0 &&
                                    selectedFiles[fileIndex].name}
                            </Typography>
                        </Box>
                        {pdfPages.length > 0 && (
                            <Box display="flex" alignItems="center">
                                <TextField
                                    className="pdf-goto-page"
                                    variant="outlined"
                                    type="number"
                                    value={pageNo}
                                    inputProps={{
                                        "aria-label": "Current Page Number",
                                    }}
                                    onChange={(event) => {
                                        const pageNo = event.target.value;
                                        setPageNo(pageNo);
                                        setFocusPageNo(pageNo);
                                    }}
                                />
                                <Typography className="ml-2">{`/ ${pdfPages.length}`}</Typography>

                                <Box className="zoom-section flex ml-4">
                                    <Button
                                        className="zoom-btn"
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        onClick={() => {
                                            setZoom(zoom + 0.25);
                                        }}
                                        aria-label="Increase Zoom"
                                    >
                                        <ZoomInIcon />
                                    </Button>
                                    <Button
                                        className="zoom-btn mx-2"
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        onClick={() => {
                                            setZoom(1);
                                        }}
                                        aria-label="Reset Zoom"
                                    >
                                        <AspectRatioIcon />
                                    </Button>
                                    <Button
                                        className="zoom-btn"
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        onClick={() => {
                                            if (zoom > 0.25) {
                                                setZoom(zoom - 0.25);
                                            }
                                        }}
                                        aria-label="Decrease Zoom"
                                    >
                                        <ZoomOutIcon />
                                    </Button>
                                </Box>
                            </Box>
                        )}

                        <Box>
                            <Button
                                className="cursor-pointer disable-button-hover"
                                endIcon={<CloseIcon />}
                                onClick={actionClose}
                                disableFocusRipple
                                disableRipple
                                aria-label="Close preview screen"
                            ></Button>
                        </Box>
                    </Box>

                    <Grid container className="preview-container">
                        <Grid
                            item
                            sm={`${
                                policyState.drawRedactionEnabled ||
                                policyState.customRedactionEnabled ||
                                policyState.templateRedactionEnabled
                                    ? 9
                                    : 12
                            }`}
                            className="redact-preview-section bg-whiteThree"
                        >
                            {selectedFiles.length > 0 && (
                                <PDFViewer
                                    ref={pdfViewer}
                                    isPDF={selectedFiles[fileIndex].isPDF}
                                    previewUrl={
                                        selectedFiles[fileIndex].preview
                                    }
                                    fileIndex={fileIndex}
                                    onPDFLoad={onPDFLoad}
                                    onActivePageChanged={(page) => {
                                        setPageNo(page + 1);
                                    }}
                                ></PDFViewer>
                            )}
                        </Grid>
                        {(policyState.drawRedactionEnabled ||
                            policyState.customRedactionEnabled ||
                            policyState.templateRedactionEnabled) && (
                            <Grid
                                item
                                sm={3}
                                className="redact-options-container "
                            >
                                <Scrollbars
                                    autoHide
                                    renderTrackHorizontal={(props) => (
                                        <div
                                            {...props}
                                            className="track-horizontal"
                                            style={{ display: "none" }}
                                        />
                                    )}
                                >
                                    {policyState.templateRedactionEnabled && (
                                        <>
                                            <Box className="option-heading">
                                                Redact
                                            </Box>
                                            <Box
                                                className="option-description"
                                                py={2}
                                            >
                                                You can redact information from
                                                this document by selecting one
                                                or multiple sensitive
                                                information categories, adding
                                                your own keywords or numbers or
                                                using the custom redaction
                                                function.
                                            </Box>
                                            <Box>
                                                <InputLabel
                                                    id="demo-mutiple-chip-label"
                                                    className="option-label"
                                                >
                                                    Select from below
                                                    pre-defined template(s) to
                                                    redact:
                                                </InputLabel>
                                                <FormControl
                                                    className="options-container"
                                                    variant="outlined"
                                                >
                                                    <div className="default-keyword-wrapper">
                                                        <div
                                                            // tabIndex="0"
                                                            {...getRootProps()}
                                                            aria-label="Pre-Defined Template keywords to redact"
                                                        >
                                                            <div
                                                                ref={
                                                                    setAnchorEl
                                                                }
                                                                className={
                                                                    focused
                                                                        ? "input-wrapper focused"
                                                                        : "input-wrapper"
                                                                }
                                                            >
                                                                {value.map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <Tag
                                                                            label={
                                                                                option.title
                                                                            }
                                                                            {...getTagProps(
                                                                                {
                                                                                    index,
                                                                                }
                                                                            )}
                                                                        />
                                                                    )
                                                                )}

                                                                <input
                                                                    role="combobox"
                                                                    {...getInputProps()}
                                                                    aria-expanded={
                                                                        groupedOptions.length >
                                                                        0
                                                                            ? "true"
                                                                            : "false"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {groupedOptions.length >
                                                        0 ? (
                                                            <ul
                                                                {...getListboxProps()}
                                                                className="listbox-container"
                                                            >
                                                                {groupedOptions.map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <li
                                                                            {...getOptionProps(
                                                                                {
                                                                                    option,
                                                                                    index,
                                                                                }
                                                                            )}
                                                                        >
                                                                            <Typography className="f12 myriadpro-regular ">
                                                                                {
                                                                                    option.title
                                                                                }
                                                                            </Typography>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        ) : null}
                                                    </div>
                                                </FormControl>
                                            </Box>
                                        </>
                                    )}

                                    {/** Custom words */}
                                    {policyState.customRedactionEnabled && (
                                        <>
                                            <Box mt={3}>
                                                <InputLabel
                                                    id="demo-mutiple-chip-label"
                                                    className="option-label f12 myriadpro-regular"
                                                >
                                                    Or, Add exact keywords (or
                                                    numbers) below to redact:
                                                </InputLabel>
                                                <Box className="keyword-container">
                                                    <Box className="default-keyword-wrapper">
                                                        <Box p={1}>
                                                            <TextField
                                                                id="custom-keyword"
                                                                fullWidth={
                                                                    false
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                                onKeyDown={(
                                                                    event
                                                                ) => {
                                                                    actionOnChangeKeyword(
                                                                        event
                                                                    );
                                                                }}
                                                                onBlur={(
                                                                    event
                                                                ) => {
                                                                    actionOnChangeKeyword(
                                                                        event
                                                                    );
                                                                }}
                                                                inputProps={{
                                                                    "aria-label":
                                                                        "Custom keywords to redact",
                                                                }}
                                                            />
                                                            <Box
                                                                py={1}
                                                                className="keyword"
                                                            >
                                                                {keyword.map(
                                                                    (
                                                                        template,
                                                                        index
                                                                    ) => (
                                                                        <Tag
                                                                            label={
                                                                                template
                                                                            }
                                                                            onDelete={() =>
                                                                                actionOnDeleteKeyword(
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}

                                    {/** Custom Redaction */}
                                    {policyState.drawRedactionEnabled && (
                                        <>
                                            <Box mt={3}>
                                                <InputLabel className="f12 myriadpro-regular">
                                                    Custom Redaction:
                                                </InputLabel>
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box className="cursor-pointer">
                                                    <Checkbox
                                                        disabled={
                                                            selectedFiles.length >
                                                            1
                                                        }
                                                        checked={drawBox}
                                                        onChange={(event) => {
                                                            const status =
                                                                event.target
                                                                    .checked;
                                                            setDrawBox(status);
                                                            setCanDraw(status);
                                                        }}
                                                        className="disable-button-hover"
                                                        disableFocusRipple
                                                        disableRipple
                                                        inputProps={{
                                                            "aria-label":
                                                                "Custom Redaction",
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    flexGrow={1}
                                                    ml={1}
                                                    alignItems="center"
                                                >
                                                    <Typography className="draw-box">
                                                        Custom redaction
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Box className="tooltip-container">
                                                        <Button
                                                            disableRipple
                                                            disableFocusRipple
                                                            className="file-hint"
                                                            role="tooltip"
                                                            aria-describedby="draw-hint-tooltip"
                                                        >
                                                            <img
                                                                src="/images/icons/icon-question.png"
                                                                width="18"
                                                                alt=""
                                                            />
                                                        </Button>
                                                        <Box
                                                            role="tooltip"
                                                            className="tooltip-content"
                                                            id="draw-hint-tooltip"
                                                        >
                                                            {getToolTipTitle()}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}

                                    <Box my={1}>
                                        <div className="b-light-blue-grey"></div>
                                    </Box>
                                    {redactedCustomKeywords.length !== 0 ||
                                    redactedTemplates.length !== 0 ? (
                                        <Box my={1}>
                                            <Typography className="f11 myriadpro-regular">
                                                The below templates/keywords
                                                have already been used:
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <div></div>
                                    )}
                                    <Box my={1}>
                                        <Grid container>
                                            {redactedTemplates.map(
                                                (keyword) => {
                                                    return (
                                                        <>
                                                            {getDefaultTemplateTooltip(
                                                                keyword
                                                            )}
                                                        </>
                                                    );
                                                }
                                            )}
                                            {redactedCustomKeywords.map(
                                                (keyword) => {
                                                    return (
                                                        <Box className="rectangle-card">
                                                            {keyword}
                                                        </Box>
                                                    );
                                                }
                                            )}
                                        </Grid>
                                    </Box>
                                    <Box className="info-box">
                                        <Box>
                                            <img
                                                src="/images/icons/icon-info.png"
                                                width="15"
                                                alt=""
                                            />
                                        </Box>
                                        <Box flexGrow={1} ml={1}>
                                            <Typography className="f12 myriadpro-regular">
                                                After you press Process you will
                                                be taken back to the list of
                                                uploaded documents. Depending on
                                                the file size, the redaction
                                                process may take a couple of
                                                minutes.
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Scrollbars>
                            </Grid>
                        )}
                    </Grid>
                    <Box p={1} className="redact-footer-container">
                        {selectedFiles.length > 1 && (
                            <Box px={2} className="file-section">
                                <Box className="page-label">
                                    File(s) {fileIndex + 1} of{" "}
                                    {selectedFiles.length}
                                </Box>
                                <ButtonGroup
                                    color="primary"
                                    size="small"
                                    aria-label="small outlined button group"
                                >
                                    <Button
                                        tabindex="-1"
                                        className="btn"
                                        disabled={fileIndex == 0}
                                        onClick={() => {
                                            setFileIndex(fileIndex - 1);
                                        }}
                                    >
                                        Prev
                                    </Button>
                                    <Button
                                        tabindex="-1"
                                        className="btn"
                                        disabled={
                                            fileIndex ===
                                            selectedFiles.length - 1
                                        }
                                        onClick={() => {
                                            setFileIndex(fileIndex + 1);
                                        }}
                                    >
                                        Next
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        )}
                        {canShowDownload() && (
                            <AppButton
                                buttonName="Download"
                                variant="outlined"
                                className="btn-preview-download"
                                isLoading={isLoading}
                                fullWidth={false}
                                loadingText="Downloading"
                                disabled={isLoading}
                                action={() => {
                                    actionDownload();
                                }}
                            />
                        )}

                        <Button
                            variant="contained"
                            size="large"
                            className="btn-close redact-btn-group"
                            onClick={actionClose}
                        >
                            Close
                        </Button>

                        <AppButton
                            buttonName="Process"
                            variant="contained"
                            className="btn-process mr-18 ml-1 redact-btn-group"
                            isLoading={processingDocument}
                            fullWidth={false}
                            loadingText="Processing"
                            disabled={
                                processingDocument ||
                                (!policyState.drawRedactionEnabled &&
                                    !policyState.customRedactionEnabled &&
                                    !policyState.templateRedactionEnabled)
                            }
                            action={() => {
                                actionRedactDocument();
                            }}
                        ></AppButton>
                    </Box>
                </Box>
                <SystemFileDownload ref={fileDownloader} />
            </DocumentPreviewContext.Provider>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        subscriptionPlan: state.subscriptionState,
        redactDrawables: state.redactDocumentState.files,
        editorTool: state.redactDocumentState.editorTool,
        selectedRequestDocuments:
            state.redactDocumentState.selectedRequestDocuments,
        policyState: state.policyState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRedactDrawables: (data) => dispatch(setDrawables(data)),
        setCanDraw: (data) => dispatch(setCanDraw(data)),
        // setSelectedRequestDocuments: (data) =>
        //     dispatch(setSelectedRequestDocuments(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPreview);
