import { Navigate } from "react-router";
import Home from "./pages/home/home";
import { RedactDocument, RedactPreview } from "./pages/home/redact";
import NotFound from "./pages/Errors/not-found";
import UnderMaintenance from "./pages/Errors/under-maintenance";
import { ReportPage } from "./pages/home/report/";
import AuthenticationPage from "./pages/AuthenticationPage";
import WelcomePage from "./pages/welcome/WelcomePage";
import store from "./state/rootReducer";
import AccessManagement from "./pages/home/accessManagement/AccessManagement";

const checkAuth = (isUserLoggedIn, pathname, component) => {
    const unsecure = ["/auth", "/welcome"];
    if (isUserLoggedIn) {
        return component;
    } else {
        const authState = store.getState().authState;
        if (
            authState &&
            authState.url &&
            authState.url.logout &&
            unsecure.indexOf(pathname) == -1
        ) {
            window.location.href = authState.url.logout;
        } else {
            return <Navigate to="/welcome" />;
        }
    }
};

const routes = (isUserLoggedIn, pathname) => {
    return [
        { path: `/auth`, element: <AuthenticationPage /> },
        { path: `/welcome`, element: <WelcomePage /> },
        { path: "/maintenance", element: <UnderMaintenance /> },
        { path: "/404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
        {
            path: "/",
            element: checkAuth(isUserLoggedIn, pathname, <Home />),
            children: [
                {
                    path: `redact`,
                    element: <RedactDocument />,
                },
                {
                    path: `redact/document`,
                    element: <RedactPreview />,
                },
                {
                    path: "",
                    element: <Navigate to="redact" />,
                },
                { path: `report`, element: <ReportPage /> },
                {
                    path: "access-management",
                    element: <AccessManagement />,
                },
            ],
        },
    ];
};

export default routes;
